<template>

  <div>

    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="filterTable"
              />
              <!-- <b-button
                variant="primary"
                :to="{name:'edit-size-price'}"
              >
                <span class="text-nowrap">Edit</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <!-- Column: User -->
        

          <template #cell(subject)="items">
           <span class="wordBreak">{{items.item.subject}}</span>
            
          </template>

          <template #cell(body)="items">
         
            <span v-html="items.item.body"></span>
          </template>
         
        
          
          <template #cell(actions)="items">
            <!-- <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
            
              <b-dropdown-item :to="{ name: 'edit-email', params: { id: items.item._id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>


              
            </b-dropdown>  -->

             <b-link :to="{ name: 'edit-email', params: { id: items.item._id } }"
                v-b-tooltip.hover.v-warning
                title="Edit"
                variant="outline-warning"
              >
                  <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
                  
              </b-link>
        </template>
        

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,VBTooltip,
  BBadge, BDropdown, BDropdownItem, BPagination, BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    vSelect,
  },
  directives: { 'b-tooltip': VBTooltip },
  data() {
    return {
       tableColumns: [
            
            { key: 'subject', label: 'Title', sortable: true , thStyle:  {width: '20%'}},
            { key: 'body', label: 'Email Template', sortable: true , thStyle:  {width: '65%'}},
            { key: 'actions', label: 'Actions', sortable: false , thStyle:  {width: '15%'}}
        ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        error_message:null,
        doc_icon: require('@/assets/images/doc.png'),
    }
  },
  methods : {
    showAlert(){
      Swal.fire({
        position: 'top-center',
        type: 'error',
        text: this.error_message,
        showConfirmButton: false,
        timer: 3000
      })
    },
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                   },
                   api: '/api//email-template-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },

    breadCrumb(){
      var item = [{
        to:{name:'admin-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'DataBase',
      },{
        to:null,
        text: 'Email Templates',
        active:true             
      }];
      return item;
    },
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
